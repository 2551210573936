import { Button } from '@unbooking/ui-kit';
import { FC, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { ITrainingProgramContent } from '@/common/api/cms';
import { CalendarOptionIcon, DownloadIcon, DownArrow } from '@icons';
import { PATHS } from '@/common/path';

interface ITrainingProgramDescription {
  trainingProgramContent: ITrainingProgramContent;
}

export const TrainingProgramDescription: FC<ITrainingProgramDescription> = ({
  trainingProgramContent,
}) => {
  const trainingUrl = `${PATHS.requestTraining}?program=${trainingProgramContent.training_program.id}`;
  const moreInfoRef = useRef<HTMLDivElement | null>(null);

  const scrollToMoreInfo = () => {
    if (moreInfoRef.current) {
      moreInfoRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  // TO DO make refactoring add handleDownloadSheet logic
  const commonInfoBlock = trainingProgramContent.paragraphs?.[0];
  const commonSecondInfoBlock = trainingProgramContent.paragraphs?.[2];
  const moreInfoSection = trainingProgramContent.paragraphs?.slice(3);

  const handleDownloadSheet = () => {
    console.log('download');
  };

  return (
    <div className="w-full">
      <div className="container text-black mx-auto w-full justify-start py-10">
        <div className="p-6 bg-white min-h-[500px] h-[70vh] w-[45%] flex flex-col ">
          <div className="cms-block">
            <h1
              className="text-4xl mb-6 font-bold"
              dangerouslySetInnerHTML={{ __html: trainingProgramContent.training_program.title }}
            />
            <p
              className="font-light"
              dangerouslySetInnerHTML={{ __html: trainingProgramContent.description }}
            />
          </div>
          <div>
            {commonInfoBlock && (
              <div className="mb-5 cms-block" key={commonInfoBlock.id}>
                {commonInfoBlock.title && (
                  <p
                    className="cms-content font-extrabold mb-3"
                    dangerouslySetInnerHTML={{ __html: commonInfoBlock.title }}
                  />
                )}
                <p
                  className="font-light"
                  dangerouslySetInnerHTML={{ __html: commonInfoBlock.description }}
                />
              </div>
            )}
            {commonSecondInfoBlock && (
              <div className="flex items-center justify-between">
                <div className="mb-5 cms-block" key={commonSecondInfoBlock.id}>
                  {commonSecondInfoBlock.title && (
                    <p
                      className="cms-content font-extrabold mb-3"
                      dangerouslySetInnerHTML={{ __html: commonSecondInfoBlock.title }}
                    />
                  )}
                  <p
                    className="font-light"
                    dangerouslySetInnerHTML={{ __html: commonSecondInfoBlock.description }}
                  />
                </div>
                <div className="flex justify-end mt-10">
                  <NavLink to={trainingUrl}>
                    <Button label="Request this training" endIcon={<CalendarOptionIcon />} />
                  </NavLink>
                </div>
              </div>
            )}
          </div>
          <div
            onClick={scrollToMoreInfo}
            className="flex items-center m-auto mb-0  underline cursor-pointer"
          >
            <span className="pr-2 pt-1">
              <DownArrow />
            </span>
            Discover more
          </div>
        </div>
      </div>
      <div
        ref={moreInfoRef}
        className="bg-white text-black  flex flex-1 flex-col justify-start gap-5 pl-[120px] py-6 pr-6"
      >
        <div
          onClick={handleDownloadSheet}
          className="flex justify-end items-center font-bold text-blue cursor-pointer"
        >
          <span className="mr-2">
            <DownloadIcon />
          </span>
          Download training spec sheet
        </div>
        <div className="lg:columns-2 gap-10 cms-block">
          {moreInfoSection.map(paragraph => (
            <div className="mb-5 cms-block" key={paragraph.id}>
              {paragraph.title && (
                <p
                  className="cms-content font-bold mb-3"
                  dangerouslySetInnerHTML={{ __html: paragraph.title }}
                />
              )}
              <p
                className="font-light"
                dangerouslySetInnerHTML={{ __html: paragraph.description }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
