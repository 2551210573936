import { FC, useEffect, useState } from 'react';
import { InputDate, InputDropdown, InputDropdownItem, InputText } from '@unbooking/ui-kit';
import dayjs from 'dayjs';
import { rangeDateDisplay } from '@/common/utils';
import { RANGE_SEPARATOR, CUSTOM_TIME_SLOTS, WEEKEND_DAY_OPTIONS } from '@/common/constants';
import { IEditComponent } from './types';
import {
  FieldValidationWarning,
  nonEmptyValidation,
} from '@/components/forms/RequestTrainingForm/common/FieldValidationWarning';

const today = new Date();

export const TimeAndLocationEdit: FC<IEditComponent> = ({
  originalTrainingRequest,
  trainingRequest,
  updateTrainingRequest,
  setConfirmEnabled,
  context,
}) => {
  const [selectedDates, setSelectedDates] = useState<string>(
    originalTrainingRequest.start_date && originalTrainingRequest.end_date
      ? rangeDateDisplay(originalTrainingRequest.start_date, originalTrainingRequest.end_date)
      : '',
  );
  const countryOptions = (context?.countries || []) as InputDropdownItem[];

  const onTrainingSelectedDate = (value: string) => {
    if (!value) {
      setSelectedDates('');
      updateTrainingRequest('start_date', '');
      updateTrainingRequest('end_date', '');
      return;
    }
    const [startDate, endDate] = value.split(RANGE_SEPARATOR);
    const startDateJS = dayjs(startDate),
      endDateJS = dayjs(endDate);

    setSelectedDates(value);
    updateTrainingRequest('start_date', startDateJS.format('YYYY-MM-DD'));
    updateTrainingRequest('end_date', endDateJS.format('YYYY-MM-DD'));
  };

  const fieldValidators: Record<string, string | null> = {
    selectedDates: nonEmptyValidation(selectedDates),
    weekend_training_days: nonEmptyValidation(trainingRequest.weekend_training_days),
    country_location: nonEmptyValidation(trainingRequest.country_location),
    preferred_place: nonEmptyValidation(trainingRequest.preferred_place),
  };
  const confirmEnabled = Object.values(fieldValidators).every(validator => validator === null);

  useEffect(() => {
    setConfirmEnabled(confirmEnabled);
  }, [confirmEnabled]);

  return (
    <div className="flex flex-col w-full gap-10">
      <div className="flex flex-col items-stretch">
        <div className="mb-2">
          State the preferred period when the training could take place (please, note that dates are
          just for reference, the Academy will confirm when the training can take place.)
        </div>
        <InputDate
          light
          value={selectedDates}
          onChange={e => onTrainingSelectedDate(e.target.value)}
          mode="range"
          name="training-months"
          placeholder="Select one or more months"
          min={today}
          timeSlots={CUSTOM_TIME_SLOTS}
        />
        <FieldValidationWarning light result={fieldValidators.selectedDates} />
      </div>
      <div className="flex flex-col items-stretch">
        <div className="mb-2">
          Specify the reason for the requested period and additional information <i>(optional)</i>
        </div>
        <InputText
          light
          value={trainingRequest.reason ?? ''}
          onChange={e => updateTrainingRequest('reason', e.target.value)}
          name="training-reason"
          placeholder="..."
        />
      </div>
      <div>
        <div className="flex flex-col items-stretch">
          <div className="mb-2">
            Our programs are based on a 6 full day working week, please indicate which day of the
            week training should NOT take place
          </div>
          <InputDropdown
            light
            value={trainingRequest.weekend_training_days as string}
            onChange={e => updateTrainingRequest('weekend_training_days', e.target.value)}
            items={WEEKEND_DAY_OPTIONS}
            name="weekend-days-select"
            placeholder="Select the day"
          />
          <FieldValidationWarning light result={fieldValidators.weekend_training_days} />
        </div>
      </div>
      <div className="flex flex-col items-stretch">
        <div className="mb-2">
          Please, select the country where you would like the training to take place
        </div>
        <InputDropdown
          light
          value={trainingRequest.country_location}
          onChange={e => updateTrainingRequest('country_location', e.target.value)}
          items={countryOptions}
          name="country-location-select"
          placeholder="Select the country"
          isSearchable
        />
        <FieldValidationWarning light result={fieldValidators.country_location} />
      </div>
      <div className="flex flex-col items-stretch">
        <div className="mb-2">
          Which are the preferred 'in country' location(s) for the training to take place?
        </div>
        <InputText
          light
          value={trainingRequest.preferred_place ?? ''}
          onChange={e => updateTrainingRequest('preferred_place', e.target.value)}
          name="preferred-place"
          placeholder="..."
        />
        <FieldValidationWarning light result={fieldValidators.preferred_place} />
      </div>
      <div className="flex flex-col items-stretch gap-2">
        <div className="mt-1">
          What is the working hours of the Country Office (e.g. 09:00 AM - 4:30 PM)?{' '}
          <i>(optional)</i>
        </div>
        <InputText
          light
          value={trainingRequest.co_working_hours ?? ''}
          onChange={e => updateTrainingRequest('co_working_hours', e.target.value)}
          name="co-working-hours"
          placeholder="..."
        />
      </div>
    </div>
  );
};

export default TimeAndLocationEdit;
