import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { ITrainingProgram } from '@/common/api/trainings';
import { getBgImageStyle } from '@/common/styles';

interface ITrainingProgramTile {
  trainingProgram: ITrainingProgram;
  className?: string;
}

export const TrainingProgramTile: FC<ITrainingProgramTile> = ({ trainingProgram, className }) => {
  return (
    <NavLink to={`/training-programs/${trainingProgram.id}`} className={className}>
      <div
        style={getBgImageStyle(trainingProgram.background_image_url)}
        className="opacity-90 hover:opacity-100 transition-all duration-200 bg-no-repeat bg-cover bg-center h-56 overflow-y-hidden rounded-[4px]"
      >
        <div className="flex flex-1 flex-col justify-end items-baseline min-h-full from-training-tile-start to-training-tile-end p-2">
          <div className="bg-white/85 p-2 rounded-[4px] w-full flex">
            <div className="p-[20px] flex justify-center items-center flex-shrink-0">
              <img src={trainingProgram.icon} alt="" />
            </div>
            <div>
              <h1 className="text-2xl mb-2">{trainingProgram.title}</h1>
              <div
                className="text-base font-light cms-block"
                dangerouslySetInnerHTML={{ __html: trainingProgram.description }}
              />
            </div>
          </div>
        </div>
      </div>
    </NavLink>
  );
};
