import { FC, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { IEditComponent, IEditSection, NeedTrainingProp, SectionType } from './types';
import { TrainingRequestDetails, updateDOTrainingRequest } from '@/common/api/digitalOffice';

import AgencyEdit from './AgencyEdit';
import TimeAndLocationEdit from './TimeAndLocationEdit';
import ParticipantsEdit from './ParticipantsEdit';
import CommunicationsEdit from './CommunicationsEdit';
import VehiclesEdit from './VehiclesEdit';
import TrainerEdit from './TrainerEdit';
import { Modal } from '@components';
import { toast } from 'react-toastify';
import { handleError } from '@/common/handleError';
import { AxiosError } from 'axios';
import { getTrainingRequestContext } from '@/common/api/trainings';
import OperationalAreaEdit from './OperationalAreaEdit';

interface ITrainingEdit extends NeedTrainingProp {
  section?: IEditSection;
  isOpen?: boolean;
  onClose: () => void;
}

const SECTION_TO_COMPONENT: Record<SectionType, FC<IEditComponent>> = {
  agency: AgencyEdit,
  'time-and-location': TimeAndLocationEdit,
  participants: ParticipantsEdit,
  communications: CommunicationsEdit,
  vehicles: VehiclesEdit,
  trainers: TrainerEdit,
  'operational-area': OperationalAreaEdit,
};

const SECTION_TO_FIELDS: Record<SectionType, Array<keyof TrainingRequestDetails>> = {
  agency: ['agencies', 'other_agencies'],
  'time-and-location': [
    'start_date',
    'end_date',
    'reason',
    'weekend_training_available',
    'weekend_training_days',
    'country_location',
    'preferred_place',
    'co_working_hours',
  ],
  participants: ['num_of_attendees'],
  communications: ['supervisor', 'focal_point'],
  vehicles: ['av_available', 'safe_area', 'recovery_equipment'],
  trainers: [
    'trainers_require_visa',
    'trainers_require_visa_comment',
    'trainers_require_medical_clearence',
    'trainers_require_medical_clearence_comment',
    'travel_advice',
  ],
  'operational-area': ['remarks'],
};

const mapRequestField = (
  trainingRequest: TrainingRequestDetails,
  field: keyof TrainingRequestDetails,
) => {
  if (['agencies', 'recovery_equipment'].includes(field)) {
    // Remap agencies and recovery_equipment to string[]
    return (
      trainingRequest[field as 'agencies' | 'recovery_equipment']?.map(item => item?.id ?? item) ??
      []
    );
  }
  return trainingRequest[field];
};

export const EditTrainingSectionModal: FC<ITrainingEdit> = ({
  section,
  trainingRequest: originalTrainingRequest,
  isOpen,
  onClose,
}) => {
  const [trainingRequest, setTrainingRequest] = useState<TrainingRequestDetails>();
  const [confirmEnabled, setConfirmEnabled] = useState(true);
  const EditComponent = section?.sectionType ? SECTION_TO_COMPONENT[section.sectionType] : null;

  const queryClient = useQueryClient();

  const updateTrainingRequestMutation = useMutation({
    mutationFn: updateDOTrainingRequest,
  });

  const { data: requestContextResponse } = useQuery({
    queryKey: ['do-edit-training-request-context'],
    queryFn: getTrainingRequestContext,
  });

  const updateTrainingRequest = (field: string, value: any) => {
    setTrainingRequest(prev => ({
      ...(prev || ({} as TrainingRequestDetails)),
      [field]: value,
    }));
  };

  const onConfirm = async () => {
    if (!confirmEnabled || !trainingRequest) return;

    try {
      await updateTrainingRequestMutation.mutateAsync({
        requestId: originalTrainingRequest.request_id,
        data: trainingRequest,
      });
      toast.success('Training Request successfully updated');
      onClose();
      // Invalidate and refetch
      await queryClient.invalidateQueries({ queryKey: ['do-training-request-detail'] });
    } catch (error) {
      handleError(error as AxiosError);
    }
  };

  useEffect(() => {
    if (section?.sectionType) {
      const fields = SECTION_TO_FIELDS[section.sectionType];

      const result = {} as Record<keyof TrainingRequestDetails, any>;
      fields.forEach(field => {
        result[field] = mapRequestField(originalTrainingRequest, field);
      });
      setTrainingRequest(result);
    }
    return () => setTrainingRequest(undefined);
  }, [originalTrainingRequest, section]);

  if (!EditComponent || !trainingRequest || !requestContextResponse) return null;

  return (
    <Modal
      size="medium"
      isOpen={isOpen}
      onClose={onClose}
      title={
        <div className="flex gap-4 items-center fill-highlight">
          {section?.icon} {section?.title}
        </div>
      }
    >
      <Modal.Body>
        <EditComponent
          originalTrainingRequest={originalTrainingRequest}
          trainingRequest={trainingRequest}
          updateTrainingRequest={updateTrainingRequest}
          setConfirmEnabled={setConfirmEnabled}
          context={requestContextResponse.data}
        />
      </Modal.Body>
      <Modal.Footer
        buttonPosition="right"
        onCancel={onClose}
        onConfirm={onConfirm}
        confirmEnabled={confirmEnabled}
      />
    </Modal>
  );
};

export default EditTrainingSectionModal;
