import { FC } from 'react';
import { ITeamMember } from '@/pages/WhoAreWePage/WhoAreWePage';
import { getFullImageUrl } from '@/common/styles';

export interface ITeamMemberRow {
  member: ITeamMember;
}

export const TeamMemberRow: FC<ITeamMemberRow> = ({ member }) => {
  const imageUrl = member.image_url ? getFullImageUrl(member.image_url) : undefined;
  return (
    <div className="flex flex-row justify-between items-start gap-12">
      <div className="min-w-[320px]">
        <img src={imageUrl} alt="Trainer" width="320px" />
      </div>
      <div className="flex flex-col justify-start items-start cms-block">
        <div className="text-2xl" dangerouslySetInnerHTML={{ __html: member.full_name }} />
        <div
          className="text-[14px] font-bold mt-1"
          dangerouslySetInnerHTML={{ __html: member.title || '' }}
        />
        <div
          className="text-default font-light mt-2 text-left max-w-[90%] leading-[1.8]"
          dangerouslySetInnerHTML={{ __html: member.description }}
        />
      </div>
    </div>
  );
};

interface ITeamList {
  teamMembers: ITeamMember[];
}

export const TeamList: FC<ITeamList> = ({ teamMembers }) => {
  return (
    <div className="flex flex-col justify-start gap-12 mt-6">
      {teamMembers.map(member => (
        <TeamMemberRow member={member} key={member.id} />
      ))}
    </div>
  );
};
