import { createContext, PropsWithChildren, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Spinner } from '@/components/common';
import { apiGet } from '@/common/axios';

type WaffleItem = { is_active: boolean; last_modified: string };

export type WaffleFlags = {
  ANALYTICS_PAGE: WaffleItem;
  HISTORY_TAB: WaffleItem;
  PARTICIPANTS_TAB: WaffleItem;
  PLANNER_PAGE: WaffleItem;
  REMARKS_FIELD: WaffleItem;
  USER_PROFILE_PAGE: WaffleItem;
};

type WafflesContextType = {
  flags: WaffleFlags;
  switches: { [key: string]: WaffleItem };
  samples: { [key: string]: WaffleItem };
};

const mergeContext = (serverContext: WafflesContextType) => {
  return {
    flags: { ...defaultWaffleContext.flags, ...serverContext.flags },
    switches: { ...defaultWaffleContext.switches, ...serverContext.switches },
    samples: { ...defaultWaffleContext.samples, ...serverContext.samples },
  };
};

const defaultFlag = { is_active: false, last_modified: '' };

const defaultWaffleContext: WafflesContextType = {
  flags: {
    ANALYTICS_PAGE: defaultFlag,
    HISTORY_TAB: defaultFlag,
    PARTICIPANTS_TAB: defaultFlag,
    PLANNER_PAGE: defaultFlag,
    REMARKS_FIELD: defaultFlag,
    USER_PROFILE_PAGE: defaultFlag,
  },
  switches: {},
  samples: {},
};

const WafflesContext = createContext<WafflesContextType>(defaultWaffleContext);

export const useWafflesContext = () => useContext(WafflesContext);

export function WafflesProvider(props: PropsWithChildren) {
  const { isLoading, data = defaultWaffleContext } = useQuery({
    queryKey: ['waffle'],
    queryFn: () => apiGet<WafflesContextType>('/waffle_status'),
    select: ({ data }) => data,
  });

  return (
    <WafflesContext.Provider value={mergeContext(data)}>
      {isLoading ? <Spinner fullScreen /> : props.children}
    </WafflesContext.Provider>
  );
}

export default WafflesProvider;
