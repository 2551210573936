import axios, { AxiosRequestConfig } from 'axios';

const envBaseUrl = import.meta.env.REACT_APP_API_URL;
export const baseUrl = envBaseUrl || `https://api.${window.location.host}`;
const baseApiUrl = `${baseUrl}/api/v1`;

export const axiosInstance = axios.create({
  baseURL: baseApiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const apiGet = async <T>(url: string, data?: any) => {
  const params = data !== undefined ? data : {};
  return await axiosInstance.get<T>(url, { params });
};

export const apiPost = async <T>(url: string, data?: any, config?: AxiosRequestConfig) => {
  return await axiosInstance.post<T>(url, data, config);
};

export const apiPut = async <T>(url: string, data?: any) => {
  return await axiosInstance.put<T>(url, data);
};

export const apiPatch = async <T>(url: string, data?: any) => {
  return await axiosInstance.patch<T>(url, data);
};

export const apiDelete = async <T>(url: string, data?: any) => {
  return await axiosInstance.delete<T>(url, data);
};
